<script>
    import axios from 'axios';
    import hostConfig from './hostConfig'
    import {verifySignature} from './verifySignature';


    const httpRequest = {

        getRequest(url,callback) {
            axios.get(url, null).then((res)=>{
                callback(res);
            });
        },

        multiPartPost(interfaceName,file,callback) {
            var host = hostConfig.baseUrl;
            var requestUrl = host + interfaceName;
            this.$axiosMultipart.post(requestUrl, file,null).then((res)=>{
                callback(res.data);
            });
        },

        post(interfaceName, params) {
            //过滤掉null的字段
            Object.keys(params).map((key) => {
                if (params[key] === null) {
                    delete  params[key];
                }
            });
            var host = hostConfig.baseUrl;
            var requestUrl = host + interfaceName;
            var qs = require('qs');
            console.info("url:" + interfaceName);
            console.info("params:" + qs.stringify(params));
            params['clientV'] = '1.0';
            params['serverV'] = '1.0';
            params['platform'] = 'frontend';
            params['signature'] = verifySignature('XI4vQYfOwSF9NCVw9P5bSiME0H8cy3OF',params);

            console.info('params:' + qs.stringify(params));
            return new Promise((resolve, reject) => {
            axios.post(requestUrl, qs.stringify(params), null).then((res)=>{
              console.info(interfaceName + ' response is ' + JSON.stringify(res));
              resolve(res.data);
            }).catch(reason => {
              reject(reason);
            });
            });
        },

        get(interfaceName, params, callback) {
            var host = hostConfig.baseUrl;
            var requestUrl = host + interfaceName;
            var qs = require('qs');
            console.info("url:" + interfaceName);
            console.info("params:" + qs.stringify(params));
            params['clientV'] = '1.0';
            params['serverV'] = '1.0';
            params['platform'] = 'frontend';

            console.info(params);
            // showLoading();
            //拼接url
            var paramsStr = "";
            for (var key in params) {
                if (paramsStr == "") {
                    paramsStr = key + '='+ params[key];
                }
                else {
                    paramsStr += "&"+ key + '=' + params[key];
                }
            }
            console.info(paramsStr)
            requestUrl = requestUrl + "?" + paramsStr;
            console.info(requestUrl)
            axios.get(requestUrl, null).then((res)=>{
                console.info('res:'+ res + 'params:' + params);
                // hideLoading();
                callback(res.data);
            });
        },

        getUrl(interfaceName, params, callback) {
            var host = hostConfig.baseUrl;
            var requestUrl = host + interfaceName;
            var qs = require('qs');
            console.info("url:" + interfaceName);
            console.info("params:" + qs.stringify(params));
            params['clientV'] = '1.0';
            params['serverV'] = '1.0';
            params['platform'] = 'frontEnd';

            console.info(params);
            //拼接url
            var paramsStr = "";
            for (var key in params) {
                if (paramsStr == "") {
                    paramsStr = key + '='+ params[key];
                }
                else {
                    paramsStr += "&"+ key + '=' + params[key];
                }
            }
            console.info(paramsStr)
            requestUrl = requestUrl + "?" + paramsStr;
            callback(requestUrl);
        }
}
export default httpRequest;
</script>
