import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import axios from 'axios';
import httpRequest from './util/httpRequest';

var axiosMultipart = axios.create({
  headers:{
    "Content-type":"multipart/form-data"
  }
});
Vue.prototype.$axiosMultipart = axiosMultipart;
Vue.prototype.$axios = axios;
Vue.prototype.$httpRequest = httpRequest;
Vue.prototype.$eventBus = new Vue();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
