<template>
  <div class="bottom-container">
    <img class="top-log-container" src="https://cdnresource.wuhanwyg.com/new_website/image/footer_logo.png"/>
    <div class="bottom-second-container" >
      <div class="first-column-container">
        <div class="company-desc-text" @click="jumpAction(0)">公司简介</div>
        <div class="company-desc-text" @click="jumpAction(1)">讲师介绍</div>
        <div class="company-desc-text" @click="jumpAction(2)">加入我们</div>
      </div>
      <div class="second-column-container">
        <div class="second-column-text">联系我们</div>
        <div class="second-item-container">
          <div class="second-item-text1">电话：(010) 8933 9682</div>
          <div class="second-item-text2">邮箱：quanmingli@lcforest.com</div>
          <div class="second-item-text3">地址：北京市朝阳区延静东里（东区）8号楼</div>
        </div>
      </div>
      <div class="third-column-container">
        <div class="official-account-container">
          <div class="official-account-title">公众号</div>
          <img class="official-account-image" src="https://cdnresource.wuhanwyg.com/new_website/image/official_account_qr_code.jpg"/>
        </div>
        <div class="enterprise-container">
          <div class="enterprise-title">企业微信</div>
          <img class="enterprise-image" src="https://cdnresource.wuhanwyg.com/new_website/image/enterprise_qr_code.jpg"/>
        </div>
      </div>
    </div>
    <div class="diver-container" v-if="isPc === true">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 2" fill="none">
        <path d="M0 1H1200" stroke="white" stroke-opacity="0.8"/>
      </svg>
    </div>
    <div class="bottom-end-container" v-if="isPc === true" @click="toBeianAction">©2024 北京乐橙森林文化科技有限公司 京ICP备2023035457号-1</div>
  </div>
</template>

<script>
  export default {
    name: "Footer",
    data(){
      return {
        screenWidth:0,
        isPc:false,
        jumpLinkList:[
          'https://mp.weixin.qq.com/s?__biz=MzkwNjUzMjAyMA==&mid=2247484731&idx=4&sn=c3fd11022599b01d48a71a9afc542cf3&chksm=c0e640e0f791c9f6d274c4236bbdd33834869123ed17c7939be01753f3f210918fcb4da20939#rd',
          'https://mp.weixin.qq.com/s?__biz=MzkwNjUzMjAyMA==&mid=2247484744&idx=1&sn=4d1df82edf4b9296f496278305dfcf63&chksm=c0e64093f791c985d4ef4a750cb7ab98fcce5e4035cb531e4141b896dfa600965ca24ed79d9e#rd',
          'https://www.zhipin.com/gongsi/38c7c55ad0d2e5fc1X1z39q-EFM~.html?ka=search_rcmd_company_name_38c7c55ad0d2e5fc1X1z39q-EFM~_custompage'
        ]
      }
    },
    mounted(){
      this.screenWidth = document.body.clientWidth;
      if (this.screenWidth > 640){
        console.info("this.screenWidth",this.screenWidth)
        this.isPc = true;
      }
    },
    methods:{
      toBeianAction() {
        window.location.href = 'https://beian.miit.gov.cn/#/Integrated/index';
      },
      jumpAction(index) {
        window.location.href = this.jumpLinkList[index];
      }
    }
  };
</script>

<style scoped>

  @media screen and (min-width: 640px){
    .bottom-container {
      width: 100%;
      height: 30.9375rem;
      background: #1E1E1E;
    }
  }

  @media screen and (min-width: 0) and (max-width: 640px){
    .bottom-container{
      width: 100%;
      height: 31.5rem;
      background: #1E1E1E;
    }
  }
  @media screen and (min-width: 640px){
    .top-log-container{
      width: 14.4375rem;
      height: 5.4375rem;
      flex-shrink: 0;
      margin-top: 2.81rem;
      margin-left:21.31rem ;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .top-log-container{
      width: 9.125rem;
      height: 3.4375rem;
      flex-shrink: 0;
      margin-top: 2rem;
      margin-left:0.56rem ;
    }
  }
  @media screen and (min-width: 640px){
    .bottom-second-container{
      display:  flex;
      flex-direction: row;
      /*width: 100%;*/
      margin-left: 22.5rem;
      margin-right: 22.5rem;
      margin-top: 2.31rem;

    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .bottom-second-container{
      display:  flex;
      flex-direction: column;
      /*width: 100%;*/
      margin-top: 1.62rem;
    }
  }

  @media screen and (min-width: 640px){
    .first-column-container{
      display:  -webkit-flex;
      -webkit-justify-content: space-between;
      flex-direction: column;
      float: left;
    }
  }

  @media screen and (min-width: 0) and (max-width: 640px){
    .first-column-container{
      display:  -webkit-flex;
      -webkit-justify-content: space-between;
      flex-direction: row;
      margin-left: 1.25rem;
      margin-right: 1.25rem;
    }
  }

  @media screen and (min-width: 640px){
    .company-desc-text{
      color: #FFF;
      font-family: "PingFang SC";
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.25rem;
      cursor: pointer;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .company-desc-text{
      color: #FFF;
      font-family: "PingFang SC";
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
      cursor: pointer
    }
  }


  @media screen and (min-width: 640px){
    .second-column-container{
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      position: relative;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .second-column-container{
      display:  -webkit-flex;
      -webkit-justify-content: space-between;
      flex-direction: column;
      line-height: 1rem;
      margin-top: 2.81rem;
      margin-left: 1.25rem;
    }
  }
  @media screen and (min-width: 640px){
    .second-column-text{
      color: #FFF;
      font-family: "PingFang SC";
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.25rem;
      margin-bottom: 1rem;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .second-column-text{
      color: #FFF;
      font-family: "PingFang SC";
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.25rem;
      margin-bottom: 1rem;
    }
  }

  @media screen and (min-width: 640px){
    .second-item-container{
      display:  -webkit-flex;
      -webkit-justify-content: space-between;;
      flex-direction: column;
      color: rgba(255, 255, 255, 0.80);
      font-family: "PingFang SC";
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
      height: 100%;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .second-item-container{
      display:  -webkit-flex;
      -webkit-justify-content: space-between;
      flex-direction: column;
      color: rgba(255, 255, 255, 0.80);
      font-family: "PingFang SC";
      font-size: 0.9375rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
    }
  }

  .second-item-text1{
  }
  .second-item-text2{

  }
  .second-item-text3{
  }
  @media screen and (min-width: 640px){
    .third-column-container{
      display: flex;
      flex-direction: row;
      float: right;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .third-column-container{
      display:  -webkit-flex;
      -webkit-justify-content: space-between;
      flex-direction: row;
      margin-top: 2.19rem;
      margin-bottom:3.46rem ;
      margin-left: 3.81rem;
      margin-right: 3.88rem;
    }
  }
  .official-account-container{
    display:  -webkit-flex;
    -webkit-justify-content: space-between;;
    flex-direction: column;
  }



  @media screen and (min-width: 640px){
    .official-account-title {
      color: #FFF;
      font-family: "PingFang SC";
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.25rem;
      display: flex;
      justify-content: center;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .official-account-title {
      color: #FFF;
      font-family: "PingFang SC";
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
      display: flex;
      justify-content: center;
    }
  }


  .official-account-image{
    width: 5.125rem;
    height: 5.125rem;
    margin-top: 1rem;
  }

  @media screen and (min-width: 640px){
    .enterprise-container{
      display: flex;
      flex-direction: column;
      margin-left: 2.5rem;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .enterprise-container{
      display: flex;
      flex-direction: column;
      margin-left: 5.5rem;
    }
  }


  @media screen and (min-width: 640px){
    .enterprise-title{
      color: #FFF;
      font-family: "PingFang SC";
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.25rem; /* 111.111% */
      display: flex;
      justify-content: center;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .enterprise-title{
      color: #FFF;
      font-family: "PingFang SC";
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
      display: flex;
      justify-content: center;
    }
  }


  .enterprise-image{
    width: 5.125rem;
    height: 5.125rem;
    margin-top: 1rem;
  }


  .diver-container{
    margin-left: 22.5rem;
    margin-right: 22.5rem;
    margin-top: 4.88rem;
    stroke-width: 1px;
    stroke: rgba(255, 255, 255, 0.80);
  }
.bottom-end-container{
  margin-left: 22.5rem;
  margin-right: 22.5rem;
  margin-top:2.5rem ;
  margin-bottom: 4.37rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: rgba(255, 255, 255, 0.80);
  text-align: center;
  font-family: "PingFang SC";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  cursor: pointer;
}

</style>

