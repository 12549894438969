export function setLocalValue(key, value) {
  localStorage.setItem(key, value)
}

export function getLocalValue(key) {
  if (key === null || key === '') {
    return null;
  }
  var value = localStorage.getItem(key);
  if (value === null || value === 'null' || value === 'NULL' || value === undefined || value === 'undefined' || value.length === 0 || value === '') {
    return null;
  }
  return value;
}
