<template>
  <div class="header">
    <div class="header-container" :style="{backgroundColor:bgColor}" >
        <img class="logo" src="https://cdnresource.wuhanwyg.com/new_website/image/home_page_log.png" @click="backToMain"/>
        <el-menu v-if="isPc" :default-active="activeMenuIndex" menu-trigger="click" text-color="#D11721" active-text-color="#D11721" class="menu" mode="horizontal" @select="handleMenuSelect">
          <el-menu-item v-for="(item,index) in menuList" :key="item.id" :index="(index+1).toString()">{{item.name}}</el-menu-item>
        </el-menu>
      <el-popover placement="bottom"
                  trigger="click"
                 v-if="isPc">
        <div class="hover-qr-code-container">
          <img class="hover-img-item" src="https://cdnresource.wuhanwyg.com/new_website/image/public_qr_code.png"/>
          <div class="hover-text-item" >关注公众号</div>
        </div>
        <div class="button-container" slot="reference">关注公众号</div>
      </el-popover>

      <div v-if="!isPc" class="menu-button-container" @click="clickMenu">菜单</div>
    </div>

    <div class="menu-container" v-if="isOpenMenu && !isPc">
      <div class="menu-second-container">
        <div class="cancel-container" >
          <img class="cancel-image" @click="clickCancel" src="https://cdnresource.wuhanwyg.com/new_website/image/menu_cancel.png" />
        </div>
        <div class="menu-item-container" v-for="item in menuList" :key="item.id" @click="jumpAction(item)">
          <div class="menu-text">{{item.name}}</div>
          <img class="menu-image" src="https://cdnresource.wuhanwyg.com/new_website/image/menu_confirm.png"/>
        </div>
      </div>
    </div >
  </div>
</template>

<script>

  import interfaceUrl from '../util/httpInterface';
  import localStorageKey from '../util/localStorageKey'
  import {setLocalValue,getLocalValue} from "../util/localStorageUtil";
  export default {
    name: "Header",
    data(){
      return {
        activeMenuIndex:'1',
        businessLineList:[],
        menuList:[
          {
            id:'main',
            name:'乐橙森林'
          }
        ],
        screenWidth:0,
        isPc:false,
        isOpenMenu:false,
        bgColor:'transparent',
        lastIndex:'1'
      }
    },
    methods:{
      handleMenuSelect(index,indexPath) {
        console.info(index+''+indexPath);
        if (index === '1') {
          this.lastIndex = index;
          this.$router.push('/main');
        } else if (index === this.menuList.length.toString()) {
          this.activeMenuIndex = this.lastIndex;
          window.location.href = 'https://work.weixin.qq.com/kfid/kfc3d9b77dbf0f10bff';
        } else {
          this.lastIndex = index;
          let indexInt = parseInt(index);
          let businessLineItem = this.menuList[indexInt-1];
          this.$router.push({path:'/businessLine',query:{id:businessLineItem.id}});
        }
        let contentElement = document.getElementsByClassName('content')[0];
        contentElement.scrollTo(0,0);
      },

      async getBusinessLineList(){
        let res = await this.$httpRequest.post(interfaceUrl.getBusinessLineList, {});
        var code = res.code;
        console.info(res);
        if (code === 200) {
          this.businessLineList = res.data;
          setLocalValue(localStorageKey.businessLineData,JSON.stringify(res.data));
          for (var businessItem of res.data) {
            this.menuList.push(businessItem);
          }
          this.menuList.push({
            id:'store',
            name:'周边商城'
          });
          if (this.$route.name === 'main') {
            this.activeMenuIndex = '1';
          } else if (this.$route.name === 'businessLine') {
            for(var i = 1; i < this.menuList.length; i++) {
              let businessLineItem = this.menuList[i];
              if (businessLineItem.id === this.$route.query.id) {
                this.activeMenuIndex = (i+1).toString();
              }
            }
          }
        }else {
          this.$message.error(res.msg);
        }
      },
      clickMenu(){
        this.isOpenMenu = true;
      },
      clickCancel(){
        this.isOpenMenu = false;
        console.info("this.isOpenMenu",this.isOpenMenu)
      },
      jumpAction(item) {
        if (item.id === 'main') {
          this.$router.push('/main');
        } else if (item.id === 'store') {
          window.location.href = 'https://work.weixin.qq.com/kfid/kfc3d9b77dbf0f10bff';
        } else {
          this.$router.push({path:'/businessLine',query:{id:item.id}});
        }
        this.isOpenMenu = false;
        let contentElement = document.getElementsByClassName('content')[0];
        contentElement.scrollTo(0,0);
      },
      setBg(needColor) {
        if (needColor){
          this.bgColor = 'white';
        } else {
          this.bgColor = 'transparent';
        }
      },
      backToMain() {
        this.$router.push('/main');
      }
    },
    created(){
      let businessLineData = getLocalValue(localStorageKey.businessLineData);
      if (businessLineData !== null) {
        this.businessLineList = JSON.parse(businessLineData);
      }
    },
    mounted() {

      this.getBusinessLineList();


      this.screenWidth = document.body.clientWidth;
      if (this.screenWidth > 640){
        console.info("this.screenWidth",this.screenWidth)
        this.isPc = true;
      }
      console.info("this.isOpenMenu",this.isOpenMenu)
      console.info("this.isPc",this.isPc)
    }
  };
</script>

<style scoped>

.header{
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2000;
}

  @media screen and (min-width: 640px){
    .header-container {
      width: 100%;
      height: 5.625rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: relative;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .header-container {
      display: flex;
      flex-direction: row;
      position: relative;
      height: 4.245rem;
      align-items: center;
      justify-content: space-between;
    }
  }
  @media screen and (min-width: 640px){
    .header .logo {
      width: 13.625rem;
      height: 4.4375rem;
      cursor: pointer;
    }
  }

  @media screen and (min-width: 0) and (max-width: 640px){
    .header .logo {
      width: 8.08781rem;
      height: 2.625rem;
      margin-left: 1.28rem;
      cursor: pointer;
    }
  }

  .header .menu {

  }

  .header-left-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background-color:rgba(0,0,0,0);
  }
  .hover-button-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #D21821;

  }
.button-container{

  display: flex;
  height: 2.8125rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 62.5rem;
  background: linear-gradient(96deg, #D21821 6.13%, #6C0E12 103.78%);
  cursor: pointer;
}
.hover-qr-code-container{
  display: flex;
  width: 9.875rem;
  /*padding: 0.625rem;*/
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.625rem;
  background: #FFF;

}
.hover-img-item{
  width: 9.875rem;
}
.hover-text-item{
  color: #000;
  font-family: "PingFang SC";
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.hover-img{
  width: 1.375rem;
  height: 1.0625rem;
  transform: rotate(270deg);
  flex-shrink: 0;
}
.menu-button-container{
  display: flex;
  width: 5.4375rem;
  padding: 0.625rem;
  margin-right: 1.28rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  border-radius: 6.25rem;
  background: linear-gradient(99deg, #D11721 6.86%, #6A0006 131.32%);
  box-shadow: 0px 4px 4px 0px rgba(209, 23, 34, 0.28);
  color: #FFF;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
}
.menu-container {
  width: 23.4375rem;
  height: 97rem;
  display: flex;
  flex-direction: column;
  background: #FFF;
  box-shadow: 0px 4px 6.1px 0px rgba(209, 23, 33, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  transform: translateZ(1px);
}
.menu-second-container{
  width: 100%;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
}
.cancel-container{
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-top: 1.94rem;
  width: 100%;
}
.cancel-image{
  display: flex;
  width: 1rem;
  height: 1rem;
  align-items: center;
  flex-shrink: 0;
  color: #333333;
  margin-right: 1.25rem;

}
.menu-item-container{
  display:  -webkit-flex;
  -webkit-justify-content: space-between;
  flex-direction: row;
  margin-top: 1rem;
  border-bottom: 0.5px solid var(--Gray-Gray3, #E7E7E7);
  padding: 0.5rem 0rem;
  align-items: center;
  gap: 1rem;
  align-self: stretch;

}
.menu-text{
  color: var(--text-icon-font-gy-190, rgba(0, 0, 0, 0.90));
  font-family: "PingFang SC";
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 1.25rem;
}
.menu-image{
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1.25rem;
}
/deep/ .el-menu-item {
  color: #D11721;
  font-family: "PingFang SC";
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


</style>

<style>

  .el-menu.el-menu--horizontal {

    margin-left: 5.81rem;
    display: flex;
    height: 2.8125rem;
    padding: 0.625rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-bottom: 3px solid #D11721;

  }
  .menu.el-menu--horizontal.el-menu{
    background-color: rgba(0, 0, 0, 0);
    border-bottom: none;
  }



  /deep/ .el-submenu {
    /*font-weight: 600;*/
  }


  .el-menu.el-menu--horizontal {
    /*border-bottom-color: white;*/
    margin-left: 5.81rem;
    display: flex;
    height: 2.8125rem;
    padding: 0.625rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-bottom: 3px solid #D11721;

  }
  .menu.el-menu--horizontal.el-menu{
    background-color: rgba(0, 0, 0, 0);
    border-bottom: none;
  }

  .el-menu-item {
    font-weight: 400;
  }
  .el-menu-item.is-active{
    font-weight: 600;
    background-color: transparent !important;
  }
  .el-menu-item:hover{
    background-color: transparent !important;
  }

  .el-submenu {

  }
  .el-popover.el-popper{
    display: flex;
    width: 9.875rem;
    padding: 0.625rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.625rem;
    background: #FFF;
  }
  .popper__arrow{
    width: 1.375rem;
    height: 1.0625rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

</style>
