<template>
    <div class="page-main-container">
        <div class="top-container" :style="{backgroundImage:'url('+businessLineDetail.topImage+')'}">
            <div class="top-title" v-if="isPc">
                {{businessLineDetail.title}}
            </div>
            <div class="top-title" v-else>
                {{businessLineDetail.mobileTitle}}
            </div>
            <div class="top-content" v-if="isPc">
                {{businessLineDetail.content}}
            </div>
            <div class="top-content" v-else>
                {{businessLineDetail.mobileContent}}
            </div>
            <img class="top-content-image" :src="businessLineDetail.pcImage" v-if="isPc"/>
            <img class="top-content-image" :src="businessLineDetail.mobileImage" v-else/>
        </div>
        <div class="teacher-container">
            <div class="teacher-title" v-if="isPc">
                {{businessLineDetail.teacherTitle}}
            </div>
            <div class="teacher-title" v-else>
                {{businessLineDetail.mobileTeacherTitle}}
            </div>
            <div class="teacher-content" v-if="isPc">
                {{businessLineDetail.teacherContent}}
            </div>
            <div class="teacher-content" v-else>
                {{businessLineDetail.mobileTeacherContent}}
            </div>
            <el-carousel class="teacher-swiper"
                         :style="{boxShadow:'0px 4px 100px 40px '+businessLineDetail.mainColor}"
                         arrow="never"
                         :indicator-position="indicatorPosition"
                         :height="swiperHeight"
                         ref="teacherSwiper">
                <div class="teacher-left-arrow" @click="teacherLeftArrrowAction" v-if="isPc && businessLineDetail.teacherList.length > 1">
                </div>
                <div class="teacher-right-arrow" @click="teacherRightArrrowAction" v-if="isPc && businessLineDetail.teacherList.length > 1">
                </div>

                <el-carousel-item
                        v-for="item in businessLineDetail.teacherList"
                        :key="item.name">
                    <div class="teacher-swiper-item">
                        <div class="teacher-swiper-item-left-container">
                            <div class="teacher-swiper-name">
                                {{item.name}}
                            </div>
                            <div class="teacher-swiper-subtitle">
                                {{item.subTitle}}
                            </div>
                            <div class="teacher-swiper-content">
                                {{item.content}}
                            </div>
                        </div>
                        <div class="teacher-swiper-item-right-container" :style="{'--background':businessLineDetail.mainColor}">
                            <div class="teacher-swiper-item-right-inner-container">
                                <img class="teacher-swiper-item-right-content" :src="item.pcImage" v-if="isPc">
                                <img class="teacher-swiper-item-right-content" :src="item.mobileImage" v-else>
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="course-container">
            <div class="course-title" v-if="isPc">
                {{businessLineDetail.courseTitle}}
            </div>
            <div class="course-title" v-else>
                {{businessLineDetail.mobileCourseTitle}}
            </div>
            <div class="course-content" v-if="isPc">
                {{businessLineDetail.courseContent}}
            </div>
            <div class="course-content" v-else>
                {{businessLineDetail.mobileCourseContent}}
            </div>
            <el-carousel class="course-swiper"
                         :style="{boxShadow:'0px 4px 100px 40px '+businessLineDetail.mainColor}"
                         arrow="never"
                         :indicator-position="indicatorPosition"
                         :height="swiperHeight"
                         ref="courseSwiper">
                <div class="course-left-arrow" @click="courseLeftArrrowAction" v-if="isPc && businessLineDetail.courseList.length > 1">
                </div>
                <div class="course-right-arrow" @click="courseRightArrrowAction" v-if="isPc && businessLineDetail.courseList.length > 1">
                </div>

                <el-carousel-item
                        v-for="item in businessLineDetail.courseList"
                        :key="item.name">
                    <div class="course-swiper-item">
                        <div class="course-swiper-item-left-container" :style="{'--background':businessLineDetail.mainColor}">
                            <div class="course-swiper-item-left-inner-container">
                                <img class="course-swiper-item-left-content" :src="item.pcImage" v-if="isPc">
                                <img class="teacher-swiper-item-right-content" :src="item.mobileImage" v-else>
                            </div>
                        </div>
                        <div class="course-swiper-item-right-container">
                            <div class="course-swiper-name">
                                {{item.name}}
                            </div>
                            <div class="course-swiper-content">
                                {{item.content}}
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <v-Footer></v-Footer>
    </div>
</template>

<script>
  import vFooter from "../views/Footer";
  import interfaceUrl from '../util/httpInterface';
  import utils from '../util/utils';
  import Dotdotdot from 'dotdotdot-js';
  export default {
    name: "BusinessLine",
    components:{
      vFooter
    },
    data() {
      return {
        businessLineDetail:{}
      }
    },
    methods:{
      async getBusinessLineDetail(id){
        let res = await this.$httpRequest.post(interfaceUrl.getBusinessLineDetail, {id:id});
        var code = res.code;
        console.info(res);
        if (code === 200) {
          this.businessLineDetail = res.data;
          this.$nextTick(()=>{
            let teacherContentEleList = document.querySelectorAll( ".teacher-swiper-content" );
            for (var teacherContentEle of teacherContentEleList) {
              new Dotdotdot(teacherContentEle,{
                truncate: "letter"
              });
            }
            if (!this.isPc) {
              let courseContentEleList = document.querySelectorAll( ".course-swiper-content" );
              for (var courseContentEle of courseContentEleList) {
                new Dotdotdot(courseContentEle,{
                  truncate: "letter"
                });
              }
            }
          });
        }
      },

      teacherLeftArrrowAction() {
        this.$refs.teacherSwiper.prev();
      },

      teacherRightArrrowAction() {
        this.$refs.teacherSwiper.next();
      },

      courseLeftArrrowAction() {
        this.$refs.courseSwiper.prev();
      },

      courseRightArrrowAction() {
        this.$refs.courseSwiper.next();
      }
    },
    mounted() {
      let businessLineID = this.$route.query.id;
      console.info('businesslineid ',businessLineID);
      if (businessLineID === null || businessLineID === undefined || businessLineID.length === 0) {
        this.$router.push('main');
      } else {
        this.getBusinessLineDetail(businessLineID);
      }
      utils.slideBanner(0,this.$refs.teacherSwiper);
      utils.slideBanner(1,this.$refs.courseSwiper);
    },
    computed: {
      isPc(){
        let screenWidth = document.body.clientWidth;
        return screenWidth > 640;
      },
      swiperHeight() {
        if (this.isPc) {
          return '29.4375rem';
        }
        return '15.4375rem';
      },
      indicatorPosition() {
        if (this.isPc) {
          return 'none';
        }
        return 'none';
      }
    },
    watch:{
      '$route'(val, from) {
        console.info(val+''+from);
        if(val.query.id){
          let businessLineID = val.query.id;
          console.info('businesslineid ',businessLineID);
          if (businessLineID === null || businessLineID === undefined || businessLineID.length === 0) {
            this.$router.push('main');
          } else {
            this.getBusinessLineDetail(businessLineID);
          }
        }
      }
    }
  };
</script>

<style scoped>

    @media screen and (min-width: 640px) {

        .top-container {
            width: 100%;
            height: 55.25rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-size: cover;
        }

        .top-title,
        .teacher-title,
        .course-title {
            color: #000;
            font-size: 2.5rem;
            font-style: normal;
            font-weight: 600;
            text-align: center;
            line-height: normal;
            margin-left: 4.06rem;
            margin-right: 4.06rem;
            white-space: pre-wrap;
        }

        .top-title {
            margin-top: 11.87rem;
        }

        .teacher-title {
            margin-top: 5.62rem;
        }

        .course-title {
            margin-top: 7.5rem;
        }

        .top-content,
        .teacher-content,
        .course-content {
            width: 75rem;
            margin-top: 0.88rem;
            color: rgba(0, 0, 0, 0.80);
            text-align: center;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 400;
            white-space: pre-wrap;
        }

        .top-content-image {
            margin-top: 3.75rem;
            width: 75rem;
            height: 25rem;
        }

        .teacher-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: hidden;
        }

        .teacher-swiper {
            margin-top: 3.75rem;
            margin-bottom: 3.75rem;
            width: 75rem;
            height: 29.4375rem;
            border-radius: 1.25rem;
            border: 0.1875rem solid #FFF;
        }

        .teacher-swiper-item {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
        }

        .teacher-swiper-item-left-container {
            width: 33.625rem;
            height: 100%;
        }

        .teacher-swiper-name {
            margin-top: 4.81rem;
            margin-left: 3.81rem;
            color: #000;
            font-size: 2.5rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .teacher-swiper-subtitle {
            margin-top: 0.94rem;
            margin-left: 3.81rem;
            color: rgba(0, 0, 0, 0.80);
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2rem; /* 160% */
        }

        .teacher-swiper-content {
            margin-top: 2rem;
            margin-left: 3.81rem;
            width: 30.8125rem;
            color: rgba(0, 0, 0, 0.65);
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2rem; /* 160% */
            white-space: pre-line;
        }

        .teacher-swiper-item-right-container {
            width: 41.375rem;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
        }

        .teacher-swiper-item-right-container::before {
            content: '';
            filter: blur(3.125rem);
            -webkit-filter: blur(3.125rem);
            z-index: -1;
            position: absolute;
            width: 41.375rem;
            height: 100%;
            top: 0;
            left: 33.625rem;
            background-color: var(--background);
            overflow: hidden;
        }

        .teacher-swiper-item-right-inner-container {
            width: 35.75rem;
            height: 23.8125rem;
            border-radius: 1.25rem;
            background-color: white;
            clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%, 15% 0);
            overflow: hidden;
        }

        .teacher-swiper-item-right-inner-container::before {
            content: '';
            position: absolute;
            top: -1.25rem;
            left: -1.25rem;
            right: -1.25rem;
            bottom: -1.25rem;
            clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%, 15% 0);
            border-radius: 1.875rem;
        }

        .teacher-swiper-item-right-content {
            margin: 1%;
            width: 98%;
            height: 98%;
            border-radius: 1.25rem;
        }

        .teacher-left-arrow {
            width: 3.75rem;
            height: 3.75rem;
            position: absolute;
            left: 3.81rem;
            top: 24.19rem;
            background-image: url("https://cdnresource.wuhanwyg.com/new_website/image/swipe-left-arrow.png");
            background-size: cover;
            z-index: 1000;
            cursor: pointer;
        }

        .teacher-right-arrow {
            width: 3.75rem;
            height: 3.75rem;
            position: absolute;
            left: 9.06rem;
            top: 24.19rem;
            background-image: url("https://cdnresource.wuhanwyg.com/new_website/image/swipe-right-arrow.png");
            background-size: cover;
            z-index: 1000;
            cursor: pointer;
        }

        .course-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: hidden;
        }

        .course-swiper {
            margin-top: 3.75rem;
            margin-bottom: 3.75rem;
            width: 75rem;
            height: 29.4375rem;
            border-radius: 1.25rem;
            border: 0.1875rem solid #FFF;
        }

        .course-swiper-item {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
        }

        .course-swiper-item-left-container {
            width: 41.375rem;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }

        .course-swiper-item-left-container::before {
            content: '';
            filter: blur(3.125rem);
            -webkit-filter: blur(3.125rem);
            z-index: -1;
            position: absolute;
            width: 41.375rem;
            height: 100%;
            top: 0;
            left: 0;
            background-color: var(--background);
            overflow: hidden;
        }

        .course-swiper-item-left-inner-container {
            width: 35.75rem;
            height: 23.8125rem;
            border-radius: 1.25rem;
            background-color: white;
            clip-path: polygon(0 0, 85% 0, 100% 100%, 0 100%);
            overflow: hidden;
        }

        .course-swiper-item-left-inner-container::before {
            content: '';
            position: absolute;
            top: -1.25rem;
            left: -1.25rem;
            right: -1.25rem;
            bottom: -1.25rem;
            clip-path: polygon(0 0, 85% 0, 100% 100%, 0 100%);
            border-radius: 1.875rem;
        }

        .course-swiper-item-left-content {
            margin: 2%;
            width: 98%;
            height: 98%;
            border-radius: 1.25rem;
        }

        .course-swiper-item-right-container {
            width: 33.625rem;
            height: 100%;
        }

        .course-swiper-name {
            margin-top: 4.81rem;
            color: #000;
            font-size: 2.5rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .course-swiper-content {
            margin-top: 2.44rem;
            width: 30.8125rem;
            color: rgba(0, 0, 0, 0.65);
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2rem; /* 160% */
            white-space: pre-line;
        }

        .course-left-arrow {
            width: 3.75rem;
            height: 3.75rem;
            position: absolute;
            right: 9.06rem;
            top: 24.19rem;
            background-image: url("https://cdnresource.wuhanwyg.com/new_website/image/swipe-left-arrow.png");
            background-size: cover;
            z-index: 1000;
            cursor: pointer;
        }

        .course-right-arrow {
            width: 3.75rem;
            height: 3.75rem;
            position: absolute;
            right: 3.81rem;
            top: 24.19rem;
            background-image: url("https://cdnresource.wuhanwyg.com/new_website/image/swipe-right-arrow.png");
            background-size: cover;
            z-index: 1000;
            cursor: pointer;
        }

    }

    @media screen and (min-width: 0) and (max-width: 640px){

        .top-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-size: cover;
            overflow: hidden;
        }

        .top-title,
        .teacher-title,
        .course-title {
            color: #000;
            text-align: center;
            font-size: 1.125rem;
            font-weight: 600;
            /*line-height: normal;*/
            margin-left: 1rem;
            margin-right: 1rem;
            white-space: pre-line;
        }

        .top-title {
            margin-top: 6.56rem;
        }

        .teacher-title {
            margin-top: 3.75rem;
        }

        .course-title {
            /*margin-top: 3.75rem;*/
        }

        .top-content,
        .teacher-content,
        .course-content {
            margin-top: 1rem;
            color: rgba(0, 0, 0, 0.80);
            font-size: 0.9375rem;
            font-weight: 400;
            margin-left: 1.25rem;
            margin-right: 1.25rem;
            white-space: pre-line;
            text-align: justify;
            line-height: 1.5rem;
            z-index: 1000;

        }

        .top-content-image {
            margin-top: 1.75rem;
            margin-bottom: 3.77rem;
            width: 20.9375rem;
            height: 6.97919rem;
            border-radius: 0.5rem;
        }

        .teacher-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: hidden;
        }

        .teacher-swiper {
            margin-top: 2rem;
            width: 20.9375rem;
            height: 15.4375rem;
            margin-bottom: 4.56rem;
            border-radius: 0.5rem;
            border: 0.1875rem solid #FFF;
        }

        .teacher-swiper-item {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
        }

        .teacher-swiper-item-left-container {
            width: 9.49044rem;
            height: 100%;
        }

        .teacher-swiper-name {
            margin-top: 1.5rem;
            margin-left: 1rem;
            color: #000;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 600;
        }

        .teacher-swiper-subtitle {
            margin-top: 0.38rem;
            margin-left: 1rem;
            color: rgba(0, 0, 0, 0.80);
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
        }

        .teacher-swiper-content {
            margin-top: 1.37rem;
            margin-left: 1rem;
            max-height: 6.25rem;
            width: 9rem;
            color: rgba(0, 0, 0, 0.65);
            font-size: 0.8125rem;
            font-style: normal;
            font-weight: 400;
            white-space: pre-line;
        }

        .teacher-swiper-item-right-container {
            width: 11.44706rem;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
        }

        .teacher-swiper-item-right-container::before {
            content: '';
            filter: blur(3.125rem);
            -webkit-filter: blur(3.125rem);
            z-index: -1;
            position: absolute;
            width: 11.44706rem;
            height: 100%;
            top: 0;
            left: 9.49044rem;
            background-color: var(--background);
            overflow: hidden;
        }

        .teacher-swiper-item-right-inner-container {
            width: 9.97925rem;
            height: 12.91375rem;
            border-radius: 0.5rem;
            background-color: white;
            clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%, 15% 0);
            overflow: hidden;
        }

        .teacher-swiper-item-right-inner-container::before {
            content: '';
            position: absolute;
            top: -0.5rem;
            left: -0.5rem;
            right: -0.5rem;
            bottom: -0.5rem;
            clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%, 15% 0);
            border-radius: 1rem;
        }

        .teacher-swiper-item-right-content {
            margin: 1%;
            width: 98%;
            height: 98%;
            border-radius: 0.5rem;
        }

        .course-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: hidden;
        }

        .course-swiper {
            margin-top: 2rem;
            width: 20.9375rem;
            height: 15.4375rem;
            margin-bottom: 4.56rem;
            border-radius: 0.5rem;
            border: 0.1875rem solid #FFF;
        }

        .course-swiper-item {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
        }

        .course-swiper-item-left-container {
            width: 11.44706rem;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }

        .course-swiper-item-left-container::before {
            content: '';
            filter: blur(3.125rem);
            -webkit-filter: blur(3.125rem);
            z-index: -1;
            position: absolute;
            width: 11.44706rem;
            height: 100%;
            top: 0;
            left: 0;
            background-color: var(--background);
            overflow: hidden;
        }

        .course-swiper-item-left-inner-container {
            width: 9.97925rem;
            height: 12.91375rem;
            border-radius: 0.5rem;
            background-color: white;
            clip-path: polygon(0 0, 85% 0, 100% 100%, 0 100%);
            overflow: hidden;
        }

        .course-swiper-item-left-inner-container::before {
            content: '';
            position: absolute;
            top: -0.5rem;
            left: -0.5rem;
            right: -0.5rem;
            bottom: -0.5rem;
            clip-path: polygon(0 0, 85% 0, 100% 100%, 0 100%);
            border-radius: 1rem;
        }

        .course-swiper-item-left-content {
            margin: 2%;
            width: 98%;
            height: 98%;
            border-radius: 0.5rem;
        }

        .course-swiper-item-right-container {
            width: 9.49044rem;
            height: 100%;
        }

        .course-swiper-name {
            margin-top: 1.5rem;
            color: #000;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 600;
        }

        .course-swiper-content {
            margin-top: 1.12rem;
            width: 9rem;
            max-height: 6.25rem;
            line-height: 1rem;
            color: rgba(0, 0, 0, 0.65);
            font-size: 0.8125rem;
            font-style: normal;
            font-weight: 400;
            white-space: pre-line;
        }
    }


</style>
