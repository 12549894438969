<template>
  <div class="main-container">
    <el-carousel  v-if="isPc" class="top-image" :height="topImageHeight+'px'">
      <el-carousel-item v-for="item in bannerList" :key="item.id">
        <img class="image-item" :src="item.pcImageUrl" />
      </el-carousel-item>
    </el-carousel>
    <el-carousel  v-else class="top-image" ref="topSwiper" arrow="never">
      <el-carousel-item v-for="item in bannerList" :key="item.id">
        <img class="image-item" :src="item.mobileImageUrl" />
      </el-carousel-item>
    </el-carousel>

    <div class="company-desc-container">
      <div class="desc-container">
        <div class="desc-title">公司简介</div>
        <div class="desc-text-container">
          <div class="desc-text">北京乐橙森林文化科技有限公司是一家专注于互联网在线兴趣教育的企业，致力于为中老年群体提供专业、系统的国学、中医养生、大健康等方向的知识服务。</div>
          <div class="desc-text">此外，公司还深耕站桩、女性健康等领域，为广大学员提供多元化、个性化的学习体验。</div>
          <div class="desc-text">公司总部位于北京，拥有一流的行业专家讲师和专业团队成员。公司始终秉承“启迪智慧，点燃兴趣”的宗旨，矢志深耕中老年兴趣教育市场，致力于为广大学员提供优质、专业的在线教育服务。</div>
          <div class="desc-text-bottom">我们坚信，通过我们的服务，可以让更多志同道合的人找到属于自己的精神栖息地，享受学习乐趣，拥抱更加充实与健康的金色年华！</div>
        </div>
      </div>
    </div>
    <div class="company-culture-container" v-if="isPc">
      <div class="culture-first-container">
        <div class="first-left-container">
          <div class="culture-title">企业文化</div>
          <div class="culture-first-desc-container">
            <div class="first-title">价值观<br> <br> </div>
            <div class="culture-item-container">
              <div class="culture-item1">服务至上: <span class="culture-item2">以客户需求为首，积极解决问题，尊重每位客户，提供卓越服务体验。</span></div>
            </div>
            <div class="culture-item-container">
              <div class="culture-item1">以人为本: <span class="culture-item2">尊重各方多样性与个性，关注员工成长发展，营造支持性环境，鼓励创新与协作。</span></div>
            </div>
            <div class="culture-item-container">
              <div class="culture-item1">诚实守信: <span class="culture-item2">对内对外不欺瞒、不夸大，如实反映、交流，信守承诺，践行契约精神。</span></div>
            </div>
            <div class="culture-item-container">
              <div class="culture-item1">担当: <span class="culture-item2">面对任务和挑战勇于承担，遇到困难不逃避，积极求解，对结果负责。</span></div>
            </div>
          </div>
        </div>
        <div class="first-right-container">
<!--          <img class="right-culture-image" src="https://cdnresource.wuhanwyg.com/new_website/image/mobile_company_culture_image.png"/>-->
        </div>
      </div>
      <div class="culture-second-container">
        <div class="culture-item-container">
          <div class="culture-item1">简单: <span class="culture-item2">提倡高效直接的工作方式，简化流程，简洁沟通，营造人性化氛围。</span></div>
        </div>
        <div class="culture-item-container">
          <div class="culture-item1">快乐: <span class="culture-item2">倡导工作与生活平衡，在职业中找乐趣，在生活中保热情，营造开放氛围，增强凝聚力，激发创意，提升效率，让员工幸福。</span></div>
        </div>
      </div>
    </div>
    <div class="mobile-company-culture-container" v-else>
      <img class="culture-image" src="https://cdnresource.wuhanwyg.com/new_website/image/mobile_company_culture_image.png"/>
      <div class="culture-title">企业文化</div>
      <div class="culture-first-desc-container">
        <div class="first-title">价值观<br> <br> </div>
        <div class="culture-item-container">
          <div class="culture-item1">服务至上: <span class="culture-item2">以客户需求为首，积极解决问题，尊重每位客户，提供卓越服务体验。</span></div>
        </div>
        <div class="culture-item-container">
          <div class="culture-item1">以人为本: <span class="culture-item2">尊重各方多样性与个性，关注员工成长发展，营造支持性环境，鼓励创新与协作。</span></div>
        </div>
        <div class="culture-item-container">
          <div class="culture-item1">诚实守信: <span class="culture-item2">对内对外不欺瞒、不夸大，如实反映、交流，信守承诺，践行契约精神。</span></div>
        </div>
        <div class="culture-item-container">
          <div class="culture-item1">担当: <span class="culture-item2">面对任务和挑战勇于承担，遇到困难不逃避，积极求解，对结果负责。</span></div>
        </div>
        <div class="culture-item-container">
          <div class="culture-item1">简单: <span class="culture-item2">提倡高效直接的工作方式，简化流程，简洁沟通，营造人性化氛围。</span></div>
        </div>
        <div class="culture-item-container">
          <div class="culture-item1">快乐: <span class="culture-item2">面对任务和挑战勇于承担，遇到困难不逃避，积极求解，对结果负责。</span></div>
        </div>
      </div>
    </div>
    <div v-if="!isPc" class="mobile-teacher-team-container">
      <div class="teacher-team-title">名师汇聚</div>
      <div class="teacher-team-title">铸就乐橙教育品质</div>
      <div class="teacher-team-desc">北京乐橙森林文化科技有限公司之所以在中老年兴趣教育行业中独树一帜，其背后是一支实力雄厚的师资队伍。公司成立发展至今，这支队伍赢得了众多中老年学员的肯定与好评，推动公司在教育领域大放异彩。</div>
    </div>
    <div class="teacher-swiper-container">
      <div class="teacher-swiper-title-container" v-if="isPc">
        <div class="teacher-swiper-title">
          名师汇聚，铸就乐橙教育品质
        </div>
      </div>
      <swiper :options="swiperOption" ref="mySwiper" class="teacher-swiper" :style="{width:teacherSwiperWidth}" v-if="teacherDataList.length > 0">
        <swiper-slide class="teacher-swiper-item" v-for="item in teacherDataList" :key="item.name">
          <img :src="item.pcImageUrl"  alt="" v-if="isPc"/>
          <img :src="item.mobileImageUrl"  alt="" v-else/>
        </swiper-slide>
      </swiper>
    </div>

    <div class="buttom-text-container">
      <div class="text-title" v-if="isPc">坚持服务至上，传承文化启迪智慧</div>
      <div v-else>
        <div class="text-title">坚持服务至上</div>
        <div class="text-title">传承文化启迪智慧</div>
      </div>
      <div class="text-desc">成立至今，北京乐橙森林文化科技有限公司始终坚持服务至上，获得众多中老年学员的肯定与好评，在中老年兴趣教育领域成绩斐然。</div>
      <div class="text-desc">北京乐橙森林文化科技有限公司引领行业发展，受到行业内相关权威单位认可，并屡获各大平台奖项。</div>
    </div>
    <vue-slick-carousel class="awards-container" v-bind="vscSettings" v-if="isPc">
      <div class="awards-item1-container">
        <img class="item-image" src="https://cdnresource.wuhanwyg.com/new_website/image/home_page_buttom_resource.png">
        <div class="item-desc">荣获</div>
        <div class="item-desc">“2023腾讯最佳品牌营销奖”</div>
      </div>
      <div class="awards-item1-container">
        <img class="item-image" src="https://cdnresource.wuhanwyg.com/new_website/image/home_page_buttom_resource.png">
        <div class="item-desc">荣获</div>
        <div class="item-desc">“IN教育行业创新智慧”营销奖”</div>
      </div>
      <div class="awards-item1-container">
        <img class="item-image" src="https://cdnresource.wuhanwyg.com/new_website/image/home_page_buttom_resource.png">
        <div class="item-desc">荣获</div>
        <div class="item-desc">“2023年年度品牌价值奖”</div>
      </div>
      <div class="awards-item1-container">
        <img class="item-image" src="https://cdnresource.wuhanwyg.com/new_website/image/home_page_buttom_resource.png">
        <div class="item-desc">荣获</div>
        <div class="item-desc">“2022年度互联网最具突破力企业奖项”</div>
      </div>
      <div class="awards-item1-container">
        <img class="item-image" src="https://cdnresource.wuhanwyg.com/new_website/image/home_page_buttom_resource.png">
        <div class="item-desc">荣获</div>
        <div class="item-desc">“2022年品牌口碑影响力奖”</div>
      </div>
      <div class="awards-item1-container">
        <img class="item-image" src="https://cdnresource.wuhanwyg.com/new_website/image/home_page_buttom_resource.png">
        <div class="item-desc">荣获</div>
        <div class="item-desc">“2021年年度最具创新力奖”</div>
      </div>
    </vue-slick-carousel>
    <div class="awards-container" v-else>
      <div class="awards-first-container">
        <div class="awards-item1-container">
          <img class="item-image" src="https://cdnresource.wuhanwyg.com/new_website/image/home_page_buttom_resource.png">
          <div class="item-desc">荣获</div>
          <div class="item-desc">“2023腾讯最佳品牌营销奖”</div>
        </div>
        <div class="awards-item2-container">
          <img class="item-image" src="https://cdnresource.wuhanwyg.com/new_website/image/home_page_buttom_resource.png">
          <div class="item-desc">荣获</div>
          <div class="item-desc">“IN教育行业创新智慧”营销奖”</div>
        </div>
      </div>
      <div class="awards-second-container">
        <div class="awards-item1-container">
          <img class="item-image" src="https://cdnresource.wuhanwyg.com/new_website/image/home_page_buttom_resource.png">
          <div class="item-desc">荣获</div>
          <div class="item-desc">“2023年年度品牌价值奖”</div>
        </div>
        <div class="awards-item2-container">
          <img class="item-image" src="https://cdnresource.wuhanwyg.com/new_website/image/home_page_buttom_resource.png">
          <div class="item-desc">荣获</div>
          <div class="item-desc">“2022年度互联网最具突破力企业奖项”</div>
        </div>
      </div>
      <div class="awards-second-container">
        <div class="awards-item1-container">
          <img class="item-image" src="https://cdnresource.wuhanwyg.com/new_website/image/home_page_buttom_resource.png">
          <div class="item-desc">荣获</div>
          <div class="item-desc">“2022年品牌口碑影响力奖”</div>
        </div>
        <div class="awards-item2-container">
          <img class="item-image" src="https://cdnresource.wuhanwyg.com/new_website/image/home_page_buttom_resource.png">
          <div class="item-desc">荣获</div>
          <div class="item-desc">“2021年年度最具创新力奖”</div>
        </div>
      </div>
    </div>
    <v-Footer></v-Footer>
  </div>
</template>
<script>

  import vFooter from "../views/Footer";
  import interfaceUrl from '../util/httpInterface';
  import { Swipe, SwipeItem,Lazyload } from 'vant';
  import VueSlickCarousel from 'vue-slick-carousel';
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
  import 'vue-slick-carousel/dist/vue-slick-carousel.css';
  import utils from '../util/utils';
  import localStorageKey from '../util/localStorageKey'
  import {setLocalValue,getLocalValue} from "../util/localStorageUtil";
  import { swiper, swiperSlide } from "vue-awesome-swiper";
  import "swiper/dist/css/swiper.css";
  export default {
    name: "Main",
    components: {
      vFooter,
      [Swipe.name]:Swipe,
      [SwipeItem.name]:SwipeItem,
      [Lazyload.name]:Lazyload,
      swiper,
      swiperSlide,
      VueSlickCarousel
    },
    data(){
      return{
        teacherDataList:[],
        teacherSwiperWidth:0,
        screenWidth:0,
        isPc:false,
        bannerList:[],
        backgroundColor:"transparent",
        topImageHeight:'0',
        swiperOption: {
          effect: 'coverflow',
          grabCursor: true,
          centeredSlides: true,
          slidesPerView: 'auto',
          autoplay:{
            delay:1000,
            disableOnInteraction:false
          },
          coverflowEffect: {
            rotate: 5,
            stretch: 0,
            depth: 60,
            modifier: 1,
            slideShadows: true,
          },
          loop:true
        },
        vscSettings:{
          arrows: false,
          dots: false,
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 1,
          autoplay: true,
          speed: 2000,
          autoplaySpeed: 2000,
          cssEase: "linear",
          variableWidth:true
        }
      }
    },
    created(){
      let bannerData = getLocalValue(localStorageKey.mainPageBannerData);
      if (bannerData !== null) {
        this.bannerList = JSON.parse(bannerData);
      }
      this.getTeacherList();
      this.getBannerList();
    },
    mounted(){
      this.screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (this.screenWidth > 640){
        this.isPc = true;
      }
      this.setBannerHeight();
      window.addEventListener('resize', ()=>{
        this.setBannerHeight();
      });
      utils.slideBanner(0,this.$refs.topSwiper);
    },
    destoryed(){
      window.removeEventListener('resize', ()=>{});
    },
    methods:{
      async getTeacherList(){
        let res = await this.$httpRequest.post(interfaceUrl.getTeacherList, {});
        var code = res.code;
        if (code === 200) {
          this.teacherDataList = res.data;
          console.info("teacherDataList",res.data);
          let screenWidthRem = this.screenWidth/16.0;
          if (this.isPc) {
            this.swiperOption.slidesPerView = this.teacherDataList.length;
            this.teacherSwiperWidth = (15.375*this.teacherDataList.length)+'rem';
            if (this.teacherSwiperWidth > screenWidthRem) {
              this.teacherSwiperWidth = '100%';
            }
          } else {
            if (this.teacherDataList.length === 1) {
              this.teacherSwiperWidth = 13.359375+'rem';
            } else {
              this.teacherSwiperWidth = '100%';
            }
          }

          if (this.teacherDataList.length === 1) {
            this.swiperOption.autoplay = false;
            this.swiperOption.loop = false;
            this.swiperOption.grabCursor = false;
          }



        }else {
          this.$message.error(res.msg);
        }
      },
      async getBannerList(){
        let res = await this.$httpRequest.post(interfaceUrl.getBannerList, {});
        var code = res.code;
        if (code === 200) {
          this.bannerList = res.data;
          setLocalValue(localStorageKey.mainPageBannerData,JSON.stringify(res.data));
          console.info("bannerList",res.data);
        }else {
          this.$message.error(res.msg);
        }
      },
      setBannerHeight() {
        let screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        let height = screenWidth * 5 / 16;
        this.topImageHeight = height.toString();
      }
    },
    computed:{
      swiper() {
        return this.$refs.mySwiper.swiper;
      }
    }
  };
</script>

<style scoped>
  .main-container{
    width: 100%;
    height: 100%;
    /*display: flex;*/
    /*flex-direction: column;*/
  }
  @media screen and (min-width: 640px){
    .top-image{
      /*background-image: url("https://cdnresource.wuhanwyg.com/new_website/image/home_page_first_background_image.png");*/
      /*background-size: cover;*/
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .top-image{

      /*background-image: url("https://cdnresource.wuhanwyg.com/new_website/image/mobile_home_page_first_background_image.png");*/
      /*background-size: cover;*/
    }
  }
  @media screen and (min-width: 640px){
    .image-item{
      /*width: 120rem;*/
      width: 100%;
      height: 100%;
      /*height: 37.5rem;*/
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .image-item{
      /*width: 23.4375rem;*/
      width: 100%;
      height: auto;
      /*height: 23.4375rem;*/
    }
  }

  @media screen and (min-width: 640px){
    .company-desc-container{
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      background-image: url("https://cdnresource.wuhanwyg.com/new_website/image/home_page_company_desc_background_image.png");
      background-size: cover;
      /*width: 120rem;*/
      width: 100%;
      /*height: auto;*/
      height: 37.5rem;
      flex-shrink: 0;
    }
  }

  @media screen and (min-width: 0) and (max-width: 640px){
    .company-desc-container{
      display: flex;
      justify-content: center;
      flex-direction: column;
      background-image: url("https://cdnresource.wuhanwyg.com/new_website/image/mobile_home_page_company_desc_background_image.png");
      background-size: cover;
      width: 100%;
      /*height: 35.75rem;*/
    }
  }

  @media screen and (min-width: 640px){
    .desc-container{
      margin-top: 5.69rem;
      margin-right: 25rem;
      display: flex;
      flex-direction: column;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .desc-container{
      margin-left: 1.25rem;
      margin-right: 1.25rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 3.75rem;
    }
  }

  @media screen and (min-width: 640px){
    .desc-title{
      color: #000;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .desc-title{
      color: #000;
      text-align: center;
      font-size: 1.625rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 1rem;
    }
  }

  @media screen and (min-width: 640px){
    .desc-text-container{
      display: inline-flex;
      padding: 2.5rem;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 0.9375rem;
      background: rgba(255, 255, 255, 0.65);
      margin-top: 0.44rem;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .desc-text-container{
      display: flex;
      flex-direction: column;
      margin-bottom: 3.75rem;
    }
  }

  @media screen and (min-width: 640px) {
    .desc-text,
    .desc-text-bottom {
      width: 45.625rem;
      color: rgba(0, 0, 0, 0.65);
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
    }

    .desc-text {
      margin-bottom: 1rem;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .desc-text,
    .desc-text-bottom{
      color: rgba(0, 0, 0, 0.85);
      text-align: justify;
      font-size: 0.9375rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2rem;
    }

    .desc-text {
      margin-bottom: 0.5rem;
    }
  }

  .company-culture-container{
    display: flex;
    flex-direction: column;

  }
  .culture-first-container{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .first-left-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 22.06rem;
    margin-top: 5.13rem;
    height: 100%;
  }
  @media screen and (min-width: 640px){
    .culture-title{
      color: #000;
      font-family: "PingFang SC";
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .culture-title{
      color: #000;
      text-align: center;
      font-family: "PingFang SC";
      font-size: 1.625rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 2rem;
    }
  }

  @media screen and (min-width: 640px){
    .culture-first-desc-container{
      display: flex;
      flex-direction: column;
      margin-left: 0.44rem;
      margin-top: 2.12rem;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .culture-first-desc-container{
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
    }
  }

  @media screen and (min-width: 640px){
    .first-title{
      color: #D11721;
      font-family: "PingFang SC";
      font-size: 1.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 2rem;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .first-title{
      color: #D11721;
      text-align: justify;
      font-family: "PingFang SC";
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.625rem;
    }
  }

  .culture-item-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1.31rem;
  }
  @media screen and (min-width: 640px){
    .culture-item1{
      color: #000;
      font-family: "PingFang SC";
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: 2rem;
      width: 100%;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .culture-item1{
         color: #000;
         font-family: "PingFang SC";
         font-size: 1.125rem;
         font-style: normal;
         font-weight: 600;
         line-height: 1.625rem;
       }
  }

  @media screen and (min-width: 640px){
    .culture-item2{
      color: rgba(0, 0, 0, 0.65);
      font-family: "PingFang SC";
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2rem;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .culture-item2{
      color: rgba(0, 0, 0, 0.65);
      font-family: "PingFang SC";
      font-size: 0.9375rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.625rem;
    }
  }

  .first-right-container{
    display: flex;
    margin-top: 8.44rem;
    margin-right: 22.37rem;
    background-image: url("https://cdnresource.wuhanwyg.com/new_website/image/company_culture.jpg");
    background-size: cover;
    width: 23.75rem;
    height: 23.75rem;
    flex-shrink: 0;
    margin-left: 3.81rem;
  }
  .right-culture-image{

  }
  .culture-second-container{
    display: flex;
    flex-direction: column;
    margin-left: 22.5rem;
    margin-right: 22.5rem;
    margin-bottom: 5.44rem;
  }
  @media screen and (min-width: 640px){
    .company-honor-container{
      display: flex;
      flex-direction: column;
      background-image: url("https://cdnresource.wuhanwyg.com/new_website/image/company_honor.png");
      background-size: cover;
      width: 120rem;
      height: 42.375rem;
      flex-shrink: 0;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .company-honor-container{
      display: flex;
      flex-direction: column;
      background-image: url("https://cdnresource.wuhanwyg.com/new_website/image/mobile_company_honor.png");
      background-size: cover;
      width: 23.4375rem;
      height: 25.375rem;
      flex-shrink: 0;
      margin-top: 3.78rem;
    }
  }
  @media screen and (min-width: 640px){
    .honor-title{
      margin-top: 5.62rem;
      margin-left: 22.5rem;
      color: #000;
      font-family: "PingFang SC";
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .honor-title{
      color: #000;
      font-family: "PingFang SC";
      font-size: 1.625rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 4.12rem;
      margin-left: 1.25rem;
    }
  }

  @media screen and (min-width: 640px){
    .honor-item-container{
      display: flex;
      flex-direction: row;
      margin-top: 1.88rem;
      /*width: 100%;*/
      margin-left: 22.5rem;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .honor-item-container{
      display: flex;
      flex-direction: row;
      margin-top: 1.62rem;
      /*width: 100%;*/
      margin-left: 1.25rem;
    }
  }

  @media screen and (min-width: 640px){
    .item-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 18.75rem;
      height: 18.75rem;
      flex-shrink: 0;
      background-image: url("https://cdnresource.wuhanwyg.com/new_website/image/honor_item_background.png");
      background-size: cover;
      fill: rgba(255, 255, 255, 0.80);
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .item-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 13.25rem;
      height: 13.25rem;
      flex-shrink: 0;
      background-image: url("https://cdnresource.wuhanwyg.com/new_website/image/honor_item_background.png");
      background-size: cover;
      fill: rgba(255, 255, 255, 0.80);
    }
  }

  @media screen and (min-width: 640px){
    .honor-item-title{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 11.875rem;
      height: 4.0625rem;
      flex-shrink: 0;
      background-image: url("https://cdnresource.wuhanwyg.com/new_website/image/honor_item_image.png");
      background-size: cover;
      color: #000;
      font-family: "PingFang SC";
      font-size: 1.625rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 4.31rem;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .honor-item-title{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 7.5rem;
      height: 2.5625rem;
      flex-shrink: 0;
      background-image: url("https://cdnresource.wuhanwyg.com/new_website/image/honor_item_image.png");
      background-size: cover;
      color: #000;
      font-family: "PingFang SC";
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 2rem;
    }
  }

  @media screen and (min-width: 640px){
    .honor-item-desc{
      margin-top: 1.88rem;
      margin-left: 1.88rem;
      margin-right: 1.88rem;
      color: rgba(0, 0, 0, 0.65);
      font-family: "PingFang SC";
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.875rem;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .honor-item-desc{
      margin-top: 1.31rem;
      margin-left: 1.31rem;
      margin-right: 1.31rem;
      color: rgba(0, 0, 0, 0.65);
      font-family: "PingFang SC";
      font-size: 0.9375rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.875rem;
    }
  }

  @media screen and (min-width: 640px){
    .item2-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 18.75rem;
      height: 18.75rem;
      flex-shrink: 0;
      background-image: url("https://cdnresource.wuhanwyg.com/new_website/image/honor_item_background.png");
      fill: rgba(255, 255, 255, 0.80);
      margin-left: 9.38rem;
      background-size: cover;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .item2-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 13.25rem;
      height: 13.25rem;
      flex-shrink: 0;
      background-image: url("https://cdnresource.wuhanwyg.com/new_website/image/honor_item_background.png");
      background-size: cover;
      fill: rgba(255, 255, 255, 0.80);
      margin-left: 2.41rem;
    }
  }

  .teacher-team-container{
    background-image: url("https://cdnresource.wuhanwyg.com/new_website/image/teacher_team.png");
    background-size: cover;
    width: 120rem;
    height: 42.4375rem;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
  }
  /*.teacher-team-title{*/
  /*  margin-top: 5.62rem;*/
  /*  color: #000;*/
  /*  font-family: "PingFang SC";*/
  /*  font-size: 2.5rem;*/
  /*  font-style: normal;*/
  /*  font-weight: 600;*/
  /*  line-height: normal;*/
  /*}*/
  @media screen and (min-width: 640px){
    .buttom-text-container{
      background-image: url("https://cdnresource.wuhanwyg.com/new_website/image/home_page_bottom_background_image1.png");
      background-size: cover;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      /*width: 120rem;*/
      width: 100%;
      /*height: auto;*/
      height: 18.125rem;


    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .buttom-text-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 1.25rem;
      margin-right: 1.25rem;
      margin-top: 3.74rem;

    }
  }
  @media screen and (min-width: 640px){
    .text-title{
      color: #000;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 0.87rem;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .text-title{
      color: #000;
      text-align: center;
      font-family: "PingFang SC";
      font-size: 1.625rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  @media screen and (min-width: 640px){
    .text-desc{
      color: rgba(0, 0, 0, 0.80);
      text-align: center;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2rem;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .text-desc{
      margin-top: 1rem;
      color: rgba(0, 0, 0, 0.80);
      text-align: justify;
      font-family: "PingFang SC";
      font-size: 0.9375rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
    }
  }

  @media screen and (min-width: 640px){
    .awards-container{
      display: flex;
      flex-direction: row;
      margin-top: 5.63rem;
      margin-left: 15.5rem;
      margin-right: 15.5rem;
      margin-bottom: 5.63rem;
      /*width: 100%;*/
      height: 17rem;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .awards-container{
      display: flex;
      justify-content: center;
      line-height: normal;
      flex-direction: column;
      margin-top: 2rem;
      margin-left: 1.25rem;
      margin-right: 1.25rem;
      margin-bottom: 3.78rem;
    }
  }


  .awards-item1-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media screen and (min-width: 640px){
    .item-image{
      width: 18.75rem;
      height: 11.0625rem;
      margin-bottom: 0.94rem;
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .item-image{
      width: 9.18963rem;
      height: 5.42188rem;
    }
  }

  @media screen and (min-width: 640px){
    .item-desc{
      color: rgba(0, 0, 0, 0.80);
      text-align: center;
      font-size: 0.9375rem;
      font-style: normal;
      font-weight: 400;
      /*line-height: 1.5rem;*/
      /*background-color: blue;*/
    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .item-desc{
      color: rgba(0, 0, 0, 0.60);
      text-align: center;
      font-family: "PingFang SC";
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
    }
  }

  .awards-item2-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    /*width: 18.75rem;*/
    /*height: 12rem;*/
  }

  .mobile-company-culture-container{
    display: flex;
    flex-direction: column;
    margin-left: 1.31rem;
    margin-right: 1.31rem;
    margin-top: 3.75rem;
    align-items: center;
  }
  .culture-image{
    width: 20.9375rem;
    height: 10.46875rem;

  }
  .mobile-honor-item-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1.62rem;
    overflow: hidden;
  }
  .mobile-teacher-team-container{
    margin-top: 3.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .teacher-team-title{
    color: #000;
    text-align: center;
    font-family: "PingFang SC";
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .teacher-team-desc{
    color: rgba(0, 0, 0, 0.80);
    text-align: justify;
    font-family: "PingFang SC";
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    margin-top: 1rem;
    /*margin-bottom: 2rem;*/
  }
  .awards-first-container{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .awards-second-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1.75rem;
  }

  @media screen and (min-width: 640px){
    /deep/.el-carousel__container {
      height: 37.5rem;
    }
  }

  @media screen and (min-width: 0) and (max-width: 640px){
    /deep/.el-carousel__container {
      height: 23.4375rem;
    }
  }

  @media screen and (min-width: 640px){
    .teacher-swiper {
      /*margin-left: 15.25rem;*/
      /*margin-right: 15.25rem;*/
    }

    .teacher-swiper-item {
      width: 15.375rem;
      height: 24.4375rem;
    }

    .teacher-swiper-item img {
      width: 100%;
      height: 100%;
      border-radius: 1rem;
    }

    .teacher-swiper-container {
      width: 100%;
      height: 42.4375rem;
      background-image: url("https://cdnresource.wuhanwyg.com/new_website/image/teacher_swiper_background.png");
      background-size: cover;
      overflow: hidden;
    }

    .teacher-swiper-title-container {
      width: 100%;
      padding-top: 5.75rem;
      padding-bottom: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .teacher-swiper-title {
      color: #000;
      font-size: 2.5rem;
      font-weight: 600;
    }
  }

  @media screen and (min-width: 0) and (max-width: 640px){
    .teacher-swiper {
      margin-top: 2rem;
    }

    .teacher-swiper-item {
      width: 13.359375rem;
      height: 23.75rem;
    }

    .teacher-swiper-item img {
      width: 100%;
      height: 100%;
      border-radius: 1rem;
    }

    .teacher-swiper-container {
      width: 100%;
      height: 28.255rem;
      background-image: url("https://wyg-scrm-resource.oss-cn-beijing.aliyuncs.com/new_website/image/mobile_teacher_team_background.png");
      background-size: cover;
      overflow: hidden;
    }

  }


</style>
<style>
  .mobile-honor-item-container.van-swipe{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .van-swipe__track {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 13.82rem;
  }
  .van-swipe-item{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }


</style>
