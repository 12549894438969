import CryptoJS from 'crypto-js'

Date.prototype.Format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
};

const utils = {

    getQueryString: function (key) {
        let reg = new RegExp(`[?|&]${key}=([^&]+)`);
        let match = window.location.search.match(reg);
        return (match && match[1]);
    },

    yuanToFen (yuan) {
        return parseInt(yuan *100);
    },

    fenToYuan (fen) {
        return (fen/100).toFixed(2);
    },

    md5(content) {
        return CryptoJS.MD5(content).toString();
    },

    formatDate(timestamp,format) {
        return new Date(timestamp).Format(format);
    },

    isCellphone(cellphone) {
        return /^(13[0-9]|14[5-9]|15[0-35-9]|166|17[0-8]|18[0-9]|19[89])\d{8}$/.test(cellphone);
    },

    randomNumberStr(length) {
        return this.randomNum(0,10*length)+'';
    },

    randomNum(minNum,maxNum){
        switch(arguments.length){
            case 1:
                return parseInt(Math.random()*minNum+1,10);
            case 2:
                return parseInt(Math.random()*(maxNum-minNum+1)+minNum,10);
            default:
                return 0;
        }
    },

    getUploadFileName (file) {
        const point = file.name.lastIndexOf('.');
        let suffix = file.name.substr(point);
        let fileName = file.name.substr(0, point);
        var timestamp = Date.parse(new Date());
        let prefileNames = CryptoJS.MD5(`${fileName}_${timestamp}`).toString();
        let resultName = `${prefileNames}${suffix}`;
        return resultName
    },

    isEmpty(value) {
        return (value === null || value === undefined || value.length === 0);
    },

    getDateAfterSeconds(seconds,date) {
         return new Date(date.getTime()+seconds*1000)
    },

    getHourMinSec(timeInterval) {
        var result = '';
        let hour = parseInt(timeInterval / 3600);
        let min = parseInt((timeInterval-3600*hour)/60);
        let sec = parseInt((timeInterval-3600*hour-min*60));

        if (hour > 0 && hour < 10) {
           result += "0"+hour+":";
        } else {
            result += hour+":";
        }

        if (min > 0 && min < 10) {
            result += "0"+min+":";
        } else {
            result += min+":";
        }

        if (sec < 10) {
            result += "0"+sec;
        } else {
            result += sec;
        }



        return result;
    },

    isWx() {
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('micromessenger') !== -1) {
            return true;
        } else {
            return false;
        }
    },

    unixTimestampForSec() {
        return Math.round(new Date().getTime()/1000);
    },

    randomStr(length) {
        var str = "";
        let arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

        for(var i=0; i<length; i++){
            let pos = Math.round(Math.random() * (arr.length-1));
            str += arr[pos];
        }
        return str;
    },

    getUrlPrefix() {
        return window.location.protocol+"//"+window.location.host+window.location.pathname;
    },

    doIt(data) {
        let key = CryptoJS.enc.Utf8.parse('sfMMUeWyDlyeMAFZ');
        let encryptedData = CryptoJS.TripleDES.encrypt(data, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        let encryptedDataStr = encryptedData.ciphertext.toString();
        let hexStr = CryptoJS.enc.Hex.parse(encryptedDataStr);
        return hexStr.toString().toUpperCase();
    },

    supportWebp () {
        try {
            return document.createElement('canvas').toDataURL('image/webp', 0.5).indexOf('data:image/webp') === 0;
        } catch(err) {
            return false;
        }
    },

    isMobile() {
    return navigator.userAgent.match(/(phone|pad|pod|iPhone|ios|iPad|Android|Mobile|BlackBerry|IEMobile |MQQBrowser|JUC|Fennec|woSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    },

    setRem() {
    let screenWidth = document.documentElement.clientWidth;
    let baselineWidth = this.isMobile()?375:1920;
    let rem = screenWidth*16/baselineWidth;
    console.info(screenWidth,rem);
    document.documentElement.style.fontSize = rem+'px';
    },

    slideBanner(index,ref) {
        //选中的轮播图
        var box = document.querySelectorAll('.el-carousel__container')[index];
        var startPoint = 0;
        var stopPoint = 0;
        //重置坐标
        var resetPoint = function () {
          startPoint = 0;
          stopPoint = 0;
        };
        //手指按下
        box.addEventListener("touchstart", function (e) {
          //手指点击位置的X坐标
          startPoint = e.changedTouches[0].pageX;
        });
        //手指滑动
        box.addEventListener("touchmove", function (e) {
          //手指滑动后终点位置X的坐标
          stopPoint = e.changedTouches[0].pageX;
        });
        //当手指抬起的时候，判断图片滚动离左右的距离
        box.addEventListener("touchend", function (e) {
          console.info(e);
          console.log("1：" + startPoint);
          console.log("2：" + stopPoint);
          if (stopPoint === 0 || startPoint - stopPoint === 0) {
            resetPoint();
            return;
          }
          if (startPoint - stopPoint > 0) {
            resetPoint();
            ref.next();
            return;
          }
          if (startPoint - stopPoint < 0) {
            resetPoint();
            ref.prev();
          }
        });
    }

};

export default utils;
