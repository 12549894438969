<template>
  <div class="home">
    <v-header ref="headerRef"></v-header>
    <div class="content-box">
      <div class="content">
        <transition name="move" mode="out-in">
          <router-view></router-view>
        </transition>
      </div>
    </div>
    <div class="hover-container" >
<!--      <div class="hover-item-container">-->
<!--        <img class="img-item" src="https://cdnresource.wuhanwyg.com/new_website/image/free_accept.png"/>-->
<!--        <div class="text-item">免费试听</div>-->
<!--      </div>-->
<!--      <img  class="diver-img" src="https://cdnresource.wuhanwyg.com/new_website/image/Vector.png"/>-->
      <div class="hover-item-container" @click="contactMeAction">
        <img class="img-item" src="https://cdnresource.wuhanwyg.com/new_website/image/service.png"/>
        <div class="text-item">联系客服</div>
      </div>
      <img class="diver-img" v-if="isPc" src="https://cdnresource.wuhanwyg.com/new_website/image/Vector.png"/>
      <div class="hover-item-container" v-if=" isPc" @mouseover="showBlock" @mouseout="hideBlock">
        <img class="img-item" src="https://cdnresource.wuhanwyg.com/new_website/image/qr_code.png"/>
        <div class="text-item" >关注公众号</div>
      </div>
      <el-popover v-if="!isPc"
                  placement="top"
                  trigger="click">
        <div class="hover-qr-code-container-mobile">
          <img class="hover-img-item" src="https://cdnresource.wuhanwyg.com/new_website/image/public_qr_code.png"/>
          <div class="hover-text-item" >关注公众号</div>
        </div>
        <div class="button-container" slot="reference">
          <img class="mobile-img-item" src="https://cdnresource.wuhanwyg.com/new_website/image/mobile_qr_code.png"/>
          <div class="mobile-text-item">关注公众号</div>
        </div>
      </el-popover>

    </div>
    <div class="hover-qr-code-item" v-if="isShow">
      <div class="hover-qr-code-container">
        <img class="hover-img-item-left" src="https://cdnresource.wuhanwyg.com/new_website/image/public_qr_code.png"/>
        <div class="hover-text-item" >关注公众号</div>
      </div>
      <img class="hover-img" src="https://cdnresource.wuhanwyg.com/new_website/image/hover-jiantou.png"/>
    </div>
  </div>
</template>
<script>
import vHeader from "./Header.vue";

export default {
  name: "Home",
  components: {
    vHeader
  },
  data(){
    return{
      isShow:false,
      isPc:false,
    }
  },
  created(){
  },
  mounted(){
    let that = this;
    window.addEventListener('scroll', ()=>{
      let contentElement = document.getElementsByClassName('content')[0];
      var scrollDistance = contentElement.scrollTop;
      if (scrollDistance > 0) {
        that.$refs.headerRef.setBg(true);
      } else {
        that.$refs.headerRef.setBg(false);
      }
    },true);
    this.screenWidth = document.body.clientWidth;
    if (this.screenWidth > 640){
      console.info("this.screenWidth",this.screenWidth)
      this.isPc = true;
    }
  },
  destroyed() {
    window.removeEventListener('scroll',()=>{});
  },
  methods:{
    showBlock(){
      this.isShow = true;
      console.info("this.isShow",this.isShow)
    },
    hideBlock(){
      this.isShow= false;
      console.info("isShow",this.isShow)
    },
    contactMeAction() {
      window.location.href = 'https://work.weixin.qq.com/kfid/kfc7a6e60311ccc0c91';
    },

  }
};
</script>

<style scoped>

  @media screen and (min-width: 640px){
    .hover-container{
      display: flex;
      width: 4.875rem;
      padding: 1.5rem 0.625rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 0.375rem 0rem 0rem 0.375rem;
      background: #FFF;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      position: absolute;
      right: 0;
      bottom: 9.375rem;
      z-index: 1000;

    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .hover-container{
      display: flex;
      flex-direction: row;
      border-radius: 0.5rem;
      background: rgba(255, 255, 255, 0.85);
      box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(3px);
      align-items: center;
      justify-content: center;
      position: fixed;
      bottom: 1.19rem;
      left: 3.13rem;
      right: 3.13rem;
      z-index: 1000;
      height: 4.125rem;
    }
  }

  @media screen and (min-width: 640px){
    .hover-item-container{
      display: flex;
      flex-direction: column;
      /*width: 100%;*/
      align-items: center;
      justify-content: center;
    }

    .text-item{
      display: flex;
      flex-direction: row;
      color: rgba(0, 0, 0, 0.75);
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 0.62rem;

    }
  }

  @media screen and (min-width: 0) and (max-width: 640px){
    .hover-item-container{
      display: flex;
      flex-direction: column;
      /*width: 100%;*/
      align-items: center;
      justify-content: center;
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
      margin-left: 0.5rem;
    }

    .text-item{
      display: flex;
      flex-direction: row;
      color: rgba(0, 0, 0, 0.75);
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 0.5rem;

    }
  }

  .img-item{
    width: 1.25rem;
    height: 1.25rem;
  }


  @media screen and (min-width: 640px){
    .diver-img{
      transform: rotate(90deg);
      height: 2.6875rem;
      stroke-width: 1px;
      stroke: #000;
      /*margin-top: 1.56rem;*/
      /*margin-bottom: 1.56rem;*/

    }
  }
  @media screen and (min-width: 0) and (max-width: 640px){
    .diver-img{
      float: left;
      position: relative;
      height: 1.875rem;
      /*transform: rotate(90deg);*/
      stroke-width: 1px;
      stroke: #000;
      margin-left: 1.56rem;
      margin-right: 1.56rem;
      /*margin-top: 1.25rem;*/
      /*margin-bottom: 1.25rem;*/

    }
  }

  .hover-qr-code-item {
    display: flex;
    right: 6.5rem;
    /*width: 9.875rem;*/
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 5.875rem;
    z-index: 2000;

  }
  .hover-qr-code-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.625rem;
    border-radius: 0.625rem;
    background-color: white;
    margin-right: 0.5rem;
  }

  .hover-qr-code-container-mobile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 0.625rem;
      background-color: white;
  }

  .hover-img-item{
    width: 9.875rem;
  }

  .hover-img-item-left {
    width: 9.875rem;
    height: 9.875rem;
    margin-top: 0.625rem;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }

  .hover-text-item{
    color: #000;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0.625rem;
  }
  .hover-img{
    width: 1.375rem;
    height: 1.0625rem;
    /*transform: rotate(90deg);*/
    flex-shrink: 0;
    margin-left: -0.625rem;
  }
  .button-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 62.5rem;
    background: #D21C23;
    margin-left: 3.25rem;
      width: 7.3125rem;
      height: 2.0625rem;
  }
  .mobile-img-item{
    width: 1.125rem;
    height: 1.125rem;
  }
  .mobile-text-item{
    color: #FFF;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

</style>
<style>
.el-popover.el-popper{
  display: flex;
  width: 9.875rem;
  padding: 0.625rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.625rem;
  background: #FFF;

}

.popper__arrow{
  width: 1.375rem;
  height: 1.0625rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
