import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Main from "../components/Main";
import BusinessLine from '../components/BusinessLine';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: 'main'
  },
  {
    path: "/",
    name: "home",
    component: Home,
    children:[
      {
        path:"/main",
        name:"main",
        component: Main
      },
      {
        path:"/businessLine",
        name:"businessLine",
        component: BusinessLine
      }
    ]
  }
];

const router = new VueRouter({
  routes
});

export default router;
