<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

import util from './util/utils';
export default {
  name: "app",
  components: {

  },
  mounted() {
    util.setRem();
    window.onresize = () => {
      util.setRem();
    };
  },
};
</script>

<style>
  #app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    margin: 0;
    padding: 0;
  }

  .content-box {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding-bottom: 30px;
    -webkit-transition: left .3s ease-in-out;
    transition: left .3s ease-in-out;
  }

  .content {
    width: auto;
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
  }


  .page-main-container
  {
    width: 100%;
    height: 100%;
  }

  .home {
    width: 100%;
    height: 100%;
    /*overflow: hidden;*/
  }

  a {
    text-decoration: none
  }

</style>
