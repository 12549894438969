import CryptoJS from 'crypto-js'

export const verifySignature = (secretData,params) => {
    var string = '';
    //拿params，去掉signature
    delete params['signature'];
    //前追加secretData
    string = string.concat(secretData);
    //字典排序
    Object.keys(params).sort().map((key) => {
        string = string.concat(key);
        string = string.concat(params[key]);
    });
    //后追加secretData
    string = string.concat(secretData);
    console.info('string is'+string);
    //md5
    let stringAfterMd5 = CryptoJS.MD5(string).toString().toUpperCase();
    return stringAfterMd5;
};
